import { Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'

export const PromptModal: React.FC<{
  visible: boolean
  submitting?: boolean
  message: string
  placeholder?: string
  onSave: (value: string) => void
  onClose: () => void
}> = ({ visible, submitting, onSave, message, onClose, placeholder }) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue('')
  }, [visible])
  const handleSave = () => {
    onSave(value)
  }
  return (
    <Modal
      title={message}
      visible={visible}
      okButtonProps={{ loading: submitting }}
      onOk={handleSave}
      onCancel={onClose}
    >
      <Input
        placeholder={placeholder ?? message}
        allowClear={true}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onPressEnter={handleSave}
        required={true}
      />
    </Modal>
  )
}
