import React, { useState } from 'react'
import styles from './index.less'
import { Button, Form, Input, message } from 'antd'
import { Footer } from '../../components/footer'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { Callbacks } from 'rc-field-form/lib/interface'
import { SessionCreateInput } from '../../restful/generated'
import { restfulClient } from '../../restful'

const FormItem = Form.Item

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const onFinish: Callbacks<SessionCreateInput>['onFinish'] = async (value) => {
    setSubmitting(true)
    try {
      await restfulClient.sessionCreate({
        body: {
          username: value.username,
          password: value.password,
        },
      })
      document.location.href = '/workspaces/browsers'
    } catch (e) {
      const msg: string = (e?.response?.data?.message as string) ?? '登录失败'
      message.error(msg)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.lang}></div>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            <Link to='/'>
              <img alt='logo' className={styles.logo} src={logo} />
              <span className={styles.title}>Wool Harvester</span>
            </Link>
          </div>
          <div className={styles.desc}>Short the world</div>
        </div>
        <div className={styles.main}>
          <Form hideRequiredMark style={{ marginTop: 8 }} form={form} onFinish={onFinish}>
            <FormItem name='username' rules={[{ required: true, message: '用户名不可为空' }]}>
              <Input placeholder='请输入用户名' size='large' allowClear={true} />
            </FormItem>
            <FormItem
              name='password'
              rules={[
                {
                  required: true,
                  message: '密码不可为空',
                },
                {
                  min: 6,
                  max: 20,
                  message: '密码长度在 6~20 之间',
                },
              ]}
            >
              <Input size='large' type='password' placeholder='请输入密码' allowClear={true} />
            </FormItem>
            <Button size='large' className={styles.submit} type='primary' htmlType='submit' loading={submitting}>
              立即登录
            </Button>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  )
}
