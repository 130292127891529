import { browserInfo } from './defines'
import { restfulClient } from '../../restful'
import { Spin } from 'antd'
import React from 'react'

export const BrowserMigration: React.FC = () => {
  const a = browserInfo
  const [{ data, loading }] = restfulClient.useBrowserProfileMigrate({
    body: {
      webglRenderer: browserInfo.webglRenderer,
      webglVendor: browserInfo.webglVendor,
    },
  })
  if (loading) {
    return <Spin />
  }
  return <div>migrated {data.successCount}</div>
}
