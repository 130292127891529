import { RESTClient } from './generated'
import axios from 'axios'

axios.defaults.withCredentials = true

export * from './generated/api'
export const API_BASE_PATH = process.env.BACKEND_BASEPATH
export const restfulClient = new RESTClient({
  basePath: API_BASE_PATH,
})
