import React, { useEffect, useRef, useState } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table'
import { BrowserProfilePopulated, restfulClient, WorkspacePopulated } from '../../restful'
import { omit } from 'lodash'
import { Button, Form, message, Modal, Select } from 'antd'
import { UserSelectComponent } from '../user/user-select.component'
import { DateTime } from '../../components/date'

export const BrowserProfileAssign: React.FC = () => {
  const tableRef = useRef<ActionType>()
  const [moveModalVisible, setMoveModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState<number | undefined>()
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | undefined>()
  const [selectedProfile, setSelectedProfile] = useState<BrowserProfilePopulated | undefined>()
  const [workspaces, setWorkspaces] = useState<WorkspacePopulated[]>([])
  const [workspaceLoading, setWorkspaceLoading] = useState(false)
  const columns: ProColumns<BrowserProfilePopulated>[] = [
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'assigneeId',
      title: '用户名',
      render: (_, row) => {
        return row.assignee.username
      },
      hideInSearch: true,
    },
    {
      dataIndex: 'workspaceId',
      title: '工作区',
      render: (_, row) => {
        return row.workspace.name
      },
      hideInSearch: true,
    },
    {
      dataIndex: 'createdAt',
      title: '创建于',
      render: (_, row) => {
        return <DateTime timestamp={row.createdAt} />
      },
      hideInSearch: true,
    },
    {
      dataIndex: 'lastLaunchedAt',
      title: '上次启动',
      render: (_, row) => {
        return <DateTime timestamp={row.lastLaunchedAt} />
      },
      hideInSearch: true,
    },
    {
      title: '操作',
      render: (_, row) => {
        return (
          <Button
            type='link'
            onClick={() => {
              setSelectedProfile(row)
              setSelectedWorkspaceId(undefined)
              setMoveModalVisible(true)
            }}
          >
            移动
          </Button>
        )
      },
    },
  ]
  useEffect(() => {
    if (!selectedUser) {
      setWorkspaces([])
      setSelectedWorkspaceId(undefined)
    } else {
      setSelectedWorkspaceId(undefined)
      setWorkspaceLoading(true)
      restfulClient
        .workspaceAdminList({
          assigneeId: selectedUser,
          take: 100,
        })

        .then((ret) => {
          setWorkspaces(ret.data?.result ?? [])
        })
        .finally(() => {
          setWorkspaceLoading(false)
        })
    }
  }, [selectedUser])
  return (
    <PageContainer title='浏览器资料管理'>
      <Modal
        visible={moveModalVisible}
        onCancel={() => setMoveModalVisible(false)}
        title={selectedProfile ? `移动浏览器资料 ${selectedProfile.name}` : undefined}
        okButtonProps={{ disabled: !selectedWorkspaceId }}
        onOk={async () => {
          if (!selectedWorkspaceId || !selectedProfile) {
            message.error('请选择工作组')
            return
          }
          await restfulClient.browserProfileAdminMoveToWorkspace({
            body: {
              ids: [selectedProfile.id],
              workspaceId: selectedWorkspaceId,
            },
          })

          tableRef.current?.reload()
          setMoveModalVisible(false)
          message.success(`移动 ${selectedProfile.name} 成功`)
        }}
      >
        <Form style={{ marginTop: 24 }}>
          <Form.Item label='用户名'>
            <UserSelectComponent
              onChange={(v) => {
                if (v > 0) {
                  setSelectedUser(v)
                } else {
                  setSelectedUser(undefined)
                }
              }}
            />
          </Form.Item>
          <Form.Item label='工作组'>
            <Select<number>
              loading={workspaceLoading}
              value={selectedWorkspaceId}
              options={workspaces.map((workspace) => {
                return {
                  value: workspace.id,
                  label: workspace.name || workspace.id,
                }
              })}
              onChange={(value) => {
                setSelectedWorkspaceId(value)
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <ProTable
        actionRef={tableRef}
        columns={columns}
        rowKey={'id'}
        headerTitle='浏览器资料列表'
        request={async (params) => {
          const { current, pageSize } = params
          const take = pageSize && pageSize > 0 ? pageSize : 10
          const skip = current && current > 0 ? (current - 1) * take : 0
          const ret = await restfulClient.browserProfileAdminList({
            skip,
            take,
            populations: ['workspace', 'assignee'],
            sort: '-createdAt',
            ...omit(params, ['current', 'pageSize']),
          })
          return {
            data: ret.data.result,
            success: ret.status === 200,
            total: ret.data.count,
          }
        }}
      />
    </PageContainer>
  )
}
