import React from 'react'
import ReactDOM from 'react-dom'
import { DefaultLayout } from './layouts/default'
import { ConfigProvider, Result } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { LoginPage } from './modules/login/login'
import { AppErrorBoundary } from './components/error-boundary'
import { BrowserVarsViewer } from './modules/browser/vars-viewer.page'

const App: React.FC = () => {
  return (
    <div>
      <AppErrorBoundary>
        <ConfigProvider locale={zhCN}>
          <Router>
            <Switch>
              <Route path='/login'>
                <LoginPage />
              </Route>
              <Route path='/project/coinlist/browsers/:id/vars'>
                <BrowserVarsViewer />
              </Route>
              <DefaultLayout />
              <Route path='*'>
                <Result status={404} title='页面未找到' />
              </Route>
            </Switch>
          </Router>
        </ConfigProvider>
      </AppErrorBoundary>
    </div>
  )
}

ReactDOM.render(<App />, document.getElementById('container'))
