import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MainLoading } from '../../components/utils'
import { Divider, Form, Input, message, Progress } from 'antd'
import { authenticator } from 'otplib'
import copy from 'copy-to-clipboard'
import { get } from 'lodash'
import { deepEntries } from '../../utils/js'
import { useRequest } from 'ahooks'
import { useQuery } from '../../utils/router'
import { BrowserProfile } from '../../restful'

type SizeType = Parameters<typeof Form>[0]['size']

export const OTPInput: React.FC<{ code: string }> = ({ code }) => {
  const [remaining, setRemaining] = useState(authenticator.timeRemaining())
  const [value, setValue] = useState(authenticator.generate(code))
  useEffect(() => {
    setValue(authenticator.generate(code))

    const timeout = setInterval(() => {
      const remainingTime = authenticator.timeRemaining()
      if (remainingTime === 30) {
        setValue(authenticator.generate(code))
      }
      setRemaining(remainingTime)
    }, 1000)

    return () => {
      clearInterval(timeout)
    }
  }, [code])
  return (
    <Input
      readOnly={true}
      value={value}
      style={{ textAlign: 'center', cursor: 'pointer!important' }}
      onClick={() => {
        const otpCode = authenticator.generate(code)
        copy(otpCode)
        message.success(`已复制：${otpCode}`)
      }}
      prefix={
        <Progress
          type='dashboard'
          percent={(remaining / 30) * 100}
          status={remaining <= 8 ? 'exception' : 'active'}
          format={() => remaining.toString()}
          width={24}
        />
      }
    />
  )
}
export const BrowserVarsViewer = () => {
  const [componentSize, setComponentSize] = useState<SizeType | 'default'>('default')
  const { id } = useParams<{ id?: string }>()
  const query = useQuery()
  const api = query.get('api')
  const { data, loading } = useRequest(
    async () => {
      if (!api) {
        message.error('NO API')
        throw new Error('NO API')
      }
      const ret = await fetch(api)
      return (await ret.json()) as BrowserProfile
    },
    {
      ready: query.get('api') !== null,
      refreshDeps: [id],
    },
  )
  if (loading) {
    return <MainLoading />
  }
  if (!data) {
    return <>No data</>
  }
  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size)
  }
  const varEntries = deepEntries(data.vars)
  const formItemStyle = {
    marginBottom: 6,
  }
  const renderFormItem = () => {
    let currentPrefix = ''
    return varEntries.map(([label, value]) => {
      const prefix = label.split('.')[0]
      let divider = null
      if (prefix && currentPrefix !== prefix) {
        currentPrefix = prefix
        divider = <Divider>{prefix}</Divider>
      }
      if (label === 'twoFactor' || label.endsWith('twoFactor')) {
        return (
          <>
            {divider}
            <Form.Item label={label} key={label} style={formItemStyle}>
              <OTPInput code={get(data.vars, 'coinlist.twoFactor')} />
            </Form.Item>
          </>
        )
      }
      return (
        <>
          {divider}
          <Form.Item label={label} key={label} style={formItemStyle}>
            <Input
              value={value as string}
              readOnly={true}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                copy(value as string)
                message.success(`已复制：${value}`)
              }}
            />
          </Form.Item>
        </>
      )
    })
  }
  return (
    <div style={{ backgroundColor: '#f0f2f5', minHeight: 614, padding: 15 }}>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout='horizontal'
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
      >
        {renderFormItem()}
      </Form>
    </div>
  )
}
