import { PageContainer } from '@ant-design/pro-layout'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from 'antd'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { PromptModal } from '../../components/prompt.component'
import { BrowserProfile, BrowserProfileCreateInput, restfulClient } from '../../restful'
import { useForm } from 'antd/es/form/Form'
import { merge } from 'lodash'
import { useRequest } from 'ahooks'
import { useDeepCompareEffect } from 'react-use'
import { BrowserProfileImporter } from './browser-profile-importer'
import { browserInfo } from './defines'
import 'antd/es/upload/style/index.less'
import { MainCenter, MainLoading } from '../../components/utils'
import proxyCountries from 'shared/modules/geo-location/proxy-countries.json'
import { countries } from 'countries-list'
import i18nCountries from 'i18n-iso-countries'
import { Overlay } from '../../components/overlay'
import antdVars from '../../../antd.vars.json'

i18nCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'))

const CardWrapper = styled.div`
  margin-right: 10px;

  .ant-card {
    box-shadow: 3px 3px 3px #888;

    .ant-card-head {
      background: ${antdVars['primary-color']};
      padding: 0 24px;
      border-bottom: 1px solid #f0f0f0;
      border-radius: 2px 2px 0 0;
      color: white;
      min-height: 0;

      .ant-card-head-title {
        padding: 0;
      }

      .ant-card-extra {
        color: white;
        padding: 0;
      }
    }

    .ant-card-body {
      padding: 5px;
    }
  }
`

const browserCardHeadStyle = { cursor: 'pointer', marginRight: 24 }
const CARD_WIDTH_LOCAL_STORAGE_KEY = 'wool.browser.cardWidth'
const menu = (
  <Menu>
    <Menu.Item>
      <a target='_blank' rel='noopener noreferrer' href='https://www.antgroup.com'>
        打开网址
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target='_blank' rel='noopener noreferrer' href='https://www.aliyun.com'>
        关闭所有标签页
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target='_blank' rel='noopener noreferrer' href='https://www.luohanacademy.com'>
        聚焦指定元素
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target='_blank' rel='noopener noreferrer' href='https://www.luohanacademy.com'>
        点击指定元素
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target='_blank' rel='noopener noreferrer' href='https://www.luohanacademy.com'>
        输入表单值
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target='_blank' rel='noopener noreferrer' href='https://www.luohanacademy.com'>
        滚动指定高度
      </a>
    </Menu.Item>
  </Menu>
)

export interface BrowserProfileCreateProps {
  browserProfile: Partial<BrowserProfile>
  onSave: (browserProfile: BrowserProfile) => {}
}

export const BrowserProfileCreateEditor: React.FC<BrowserProfileCreateProps> = (props) => {
  const [form] = useForm()
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  }
  const tailLayout = {
    wrapperCol: { offset: 7, span: 17 },
  }
  useDeepCompareEffect(() => {
    form.setFieldsValue(props.browserProfile)
  }, [props])
  return (
    <Form
      {...layout}
      form={form}
      name='basic'
      initialValues={{ ...browserInfo, country: 'VN', language: 'vi', timezone: 'Asia/Ho_Chi_Minh' }}
      onFinish={(values) => {
        props.onSave(merge(props.browserProfile, values))
      }}
    >
      <Form.Item label='名称' name='name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label='国家' name='country' rules={[{ required: true }]}>
        <Select
          showSearch={true}
          filterOption={(input, option) => {
            const country = option?.value
            const lcInput = input.toLowerCase()
            if (!country) {
              return false
            }
            if (country.toLocaleLowerCase().includes(lcInput)) {
              return true
            }
            const countryInfo = countries[country as 'CN']
            if (!countryInfo) {
              return false
            }
            if (countryInfo.name.toLowerCase().includes(lcInput)) {
              return true
            }

            if (countryInfo.native.toLowerCase().includes(lcInput)) {
              return true
            }
            //
            // if (countryInfo.capital.toLowerCase().includes(lcInput)) {
            //   return true
            // }
            // if (countryInfo.currency.toLowerCase().includes(lcInput)) {
            //   return true
            // }
            if (countryInfo.phone.toLowerCase().includes(lcInput)) {
              return true
            }
            if (i18nCountries.getName(country, 'zh', { select: 'official' }).includes(lcInput)) {
              return true
            }
            return false
          }}
        >
          {Object.keys(proxyCountries).map((country) => {
            return (
              <Select.Option value={country} key={country}>
                {countries[country as 'CN']?.emoji} {country}
                {' - '}
                {i18nCountries.getName(country, 'zh', { select: 'official' })} - {countries[country as 'CN']?.native}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label='时区' name='timezone' rules={[{ required: true }]}>
        <Select showSearch={true}>
          {['Asia/Ho_Chi_Minh', 'Asia/Hong_Kong'].map((timezone) => {
            return (
              <Select.Option value={timezone} key={timezone}>
                {timezone}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label='语言' name='language' rules={[{ required: true }]}>
        <Select showSearch={true}>
          {['vi', 'en'].map((timezone) => {
            return (
              <Select.Option value={timezone} key={timezone}>
                {timezone}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      {[
        { label: 'CoinList 账号', name: ['vars', 'coinlist', 'account'] },
        {
          label: 'CoinList 密码',
          name: ['vars', 'coinlist', 'password'],
        },
        {
          label: 'Coinlist 2FA 密钥',
          name: ['vars', 'coinlist', 'twoFactor'],
        },
        {
          label: 'Gmail 邮箱',
          name: ['vars', 'gmail', 'email'],
        },
        {
          label: 'Gmail 密码',
          name: ['vars', 'gmail', 'password'],
        },
        { label: 'Gmail 辅助邮箱', name: ['vars', 'gmail', 'securityEmail'] },
      ].map(({ label, name }) => {
        return (
          <Form.Item label={label} name={name} rules={[{ required: true }]} key={name.toString()}>
            <Input />
          </Form.Item>
        )
      })}
      {['userAgent', 'platform', 'hardwareConcurrency', 'webglVendor', 'webglRenderer'].map((name) => {
        return (
          <Form.Item label={name} name={name} rules={[{ required: true }]} key={name}>
            <Input />
          </Form.Item>
        )
      })}
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
export const BrowserPage: React.FC = () => {
  const [maximized, setMaximized] = useState<number>(-1)
  const defaultCardWidth = parseInt(localStorage.getItem(CARD_WIDTH_LOCAL_STORAGE_KEY) ?? '600', 10)
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(0)
  const [currentEditingProfile, setCurrentEditingProfile] = useState<BrowserProfile | null>(null)
  const [editProfileWindowVisible, setEditProfileWindowVisible] = useState(false)
  const [newWorkspaceWindowVisible, setNewWorkspaceWindowVisible] = useState(false)
  const [newTabWindowVisible, setNewTabWindowVisible] = useState(false)
  const [clickWindowVisible, setClickWindowVisible] = useState(false)
  const [importProfileVisible, setImportProfileVisible] = useState(false)

  const [newBrowserProfileVisible, setNewBrowserProfileVisible] = useState(false)
  const [cardWidth, setCardWidth] = useState(defaultCardWidth)
  const [previousMaximized, setPreviousMaximized] = useState<number>(-1)
  const [sizeForm] = Form.useForm()
  const [{ data: workspaces, loading: workspacesLoading }, workspacesRefetch] = restfulClient.useWorkspaceList({})
  const [
    { data: actionSpecs, loading: actionSpecsLoading },
    actionSpecRefetch,
  ] = restfulClient.useBrowserActionGetPredefined({})
  const { data: browserProfiles, loading: browserProfilesLoading, refresh: browserProfilesRefetch } = useRequest(
    () => {
      return restfulClient.browserProfileList({ take: 200, workspaceId: currentWorkspaceId })
    },
    {
      ready: currentWorkspaceId > 0,
      refreshDeps: [currentWorkspaceId],
    },
  )

  useEffect(() => {
    if (workspaces?.result?.length > 0) {
      setCurrentWorkspaceId(workspaces.result[0].id)
    }
  }, [workspaces])
  const resetCardSize = () => {
    setCardWidth(defaultCardWidth)
    setMaximized(-1)
    sizeForm.setFieldsValue({
      cardWidth: defaultCardWidth,
    })
  }
  const browserCount = browserProfiles?.data?.count ?? 0
  const renderCard = (browserProfile: BrowserProfile, index: number) => {
    const id = browserProfile.id
    const width = maximized === index ? window.innerWidth * 0.7 : cardWidth
    const ratio = 1440 / 900
    // 10 是针对 card body 的 padding: 5px;
    const iframeHeight = (width - 10) / ratio
    const cardStyle = {
      width,
      height: iframeHeight + 35,
      marginBottom: 24,
      borderColor: previousMaximized === index ? antdVars['primary-color'] : undefined,
      shadowColor: previousMaximized === index ? antdVars['primary-color'] : undefined,
      ...(maximized === index && {
        position: 'fixed' as const,
        zIndex: 1001,
        left: 0,
        top: 30,
        right: 0,
        bottom: 0,
        margin: '0 auto',
      }),
    }
    const iframeStyle = {
      width: cardStyle.width - 10,
      height: iframeHeight,
      border: 'none',
      margin: 0,
    }
    return (
      <CardWrapper key={`browser-${id}`}>
        <Card
          title={`#${id} - ${browserProfile.name}`}
          extra={
            <>
              <Popconfirm
                title='要删除这个浏览器资料吗？'
                onConfirm={async () => {
                  try {
                    await restfulClient.browserProfilePatch({
                      id: browserProfile.id,
                      body: ({
                        status: 'DELETED',
                      } as unknown) as BrowserProfileCreateInput,
                    })
                    message.success('删除成功')
                    await browserProfilesRefetch()
                  } catch (e) {
                    message.error(`删除失败：${e.message}`)
                  }
                }}
                okText='删除'
                cancelText='不删除'
              >
                <DeleteOutlined style={browserCardHeadStyle} />
              </Popconfirm>
              <Dropdown
                overlay={
                  <Menu>
                    {actionSpecsLoading ? (
                      <Menu.Item>
                        <Spin />
                      </Menu.Item>
                    ) : (
                      actionSpecs.result.map((actionSpec) => {
                        return (
                          <Menu.Item
                            key={actionSpec.name}
                            onClick={() => {
                              restfulClient.browserActionPredefinedAction({
                                id: browserProfile.id,
                                body: {
                                  name: actionSpec.name,
                                },
                              })
                            }}
                          >
                            <a>{actionSpec.displayName}</a>
                          </Menu.Item>
                        )
                      })
                    )}
                    <Menu.Item
                      icon={<PlayCircleOutlined />}
                      onClick={async () => {
                        await restfulClient.browserActionLaunch({
                          id: browserProfile.id,
                        })
                        message.success('提交开机成功')
                      }}
                    >
                      <a>开机</a>
                    </Menu.Item>
                    <Menu.Item
                      icon={<PauseOutlined />}
                      onClick={async () => {
                        await restfulClient.browserActionStop({
                          id: browserProfile.id,
                        })
                        message.success('提交关机成功')
                      }}
                    >
                      <a>关机</a>
                    </Menu.Item>
                  </Menu>
                }
                arrow
              >
                <ToolOutlined style={browserCardHeadStyle} />
              </Dropdown>
              <Tooltip title='修改资料'>
                <EditOutlined
                  style={browserCardHeadStyle}
                  onClick={() => {
                    setCurrentEditingProfile(browserProfile)
                    setEditProfileWindowVisible(true)
                  }}
                />
              </Tooltip>
              {maximized === index && (
                <>
                  <Tooltip title='上一个'>
                    <ArrowLeftOutlined
                      style={{
                        cursor: 'pointer',
                        marginRight: 24,
                        ...(index < 1 && {
                          cursor: 'not-allowed',
                          color: 'gray',
                        }),
                      }}
                      onClick={() => {
                        if (index < 1) {
                          return
                        }
                        setMaximized(index - 1)
                      }}
                    />
                  </Tooltip>
                  <Tooltip title='下一个'>
                    <ArrowRightOutlined
                      style={{
                        cursor: 'pointer',
                        marginRight: 24,
                        ...(index >= browserCount - 1 && {
                          cursor: 'not-allowed',
                          color: 'gray',
                        }),
                      }}
                      onClick={() => {
                        if (index >= browserCount - 1) {
                          return
                        }
                        setMaximized(index + 1)
                      }}
                    />
                  </Tooltip>
                </>
              )}

              {maximized === index ? (
                <FullscreenExitOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPreviousMaximized(maximized)
                    setMaximized(-1)
                  }}
                />
              ) : (
                <FullscreenOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPreviousMaximized(maximized)
                    setMaximized(index)
                  }}
                />
              )}
            </>
          }
          style={cardStyle}
        >
          <iframe
            src={`${process.env.BROWSER_BASEPATH}/novnc/?id=${browserProfile.id}&path=${encodeURI(
              `websockify?id=${browserProfile.id}`,
            )}&autoconnect=true&resize=scale&reconnect=true`}
            style={iframeStyle}
          />
        </Card>
      </CardWrapper>
    )
  }
  return (
    <PageContainer title='浏览器列表'>
      <Overlay
        visible={maximized > -1}
        onCancel={() => {
          setPreviousMaximized(maximized)
          setMaximized(-1)
        }}
      />
      <PromptModal
        visible={newWorkspaceWindowVisible}
        message='请输入工作组名'
        onClose={() => setNewWorkspaceWindowVisible(false)}
        onSave={async (value) => {
          if (!value) {
            return message.error('工作组名称不可为空')
          }
          setNewWorkspaceWindowVisible(false)
          await restfulClient.workspaceCreate({
            body: {
              name: value,
            },
          })
          await workspacesRefetch()
          message.success('添加工作组成功')
        }}
      />
      <PromptModal
        visible={newTabWindowVisible}
        message='请输入网址'
        onClose={() => setNewTabWindowVisible(false)}
        onSave={async (value) => {
          if (!value) {
            return message.error('请输入网址')
          }
          setNewTabWindowVisible(false)
          const ret = await restfulClient.browserActionWorkspaceNewTab({
            id: currentWorkspaceId,
            body: {
              url: value,
            },
          })
          message.success(`有 ${ret.data.successCount} 个浏览器打开了网址：${value}`)
        }}
      />
      <PromptModal
        visible={clickWindowVisible}
        message='请输入要点击的按钮选择器'
        onClose={() => setClickWindowVisible(false)}
        onSave={async (value) => {
          if (!value) {
            return message.error('请输入选择器')
          }
          setClickWindowVisible(false)
          const ret = await restfulClient.browserActionWorkspaceClick({
            id: currentWorkspaceId,
            body: {
              selector: value,
            },
          })
          message.success(`有 ${ret.data.successCount} 个浏览器点击了按钮`)
        }}
      />
      <Modal
        title='创建浏览器资料'
        visible={newBrowserProfileVisible}
        footer={false}
        onCancel={() => {
          setNewBrowserProfileVisible(false)
        }}
      >
        <BrowserProfileCreateEditor
          browserProfile={{
            ...browserInfo,
            workspaceId: currentWorkspaceId,
          }}
          onSave={async (profile) => {
            await restfulClient.browserProfileCreate({
              body: profile,
            })
            setNewBrowserProfileVisible(false)
            message.success('创建浏览器资料成功')
            await browserProfilesRefetch()
          }}
        />
      </Modal>
      <Modal
        title='批量导入浏览器资料'
        visible={importProfileVisible}
        okButtonProps={{ style: { display: 'none' } }}
        onOk={() => {
          browserProfilesRefetch()
          setImportProfileVisible(false)
        }}
        onCancel={() => {
          browserProfilesRefetch()
          setImportProfileVisible(false)
        }}
      >
        <BrowserProfileImporter workspaceId={currentWorkspaceId}></BrowserProfileImporter>
      </Modal>
      <Modal
        title='修改浏览器资料'
        visible={editProfileWindowVisible}
        footer={false}
        zIndex={1002}
        onCancel={() => {
          setEditProfileWindowVisible(false)
        }}
      >
        {currentEditingProfile && (
          <BrowserProfileCreateEditor
            browserProfile={currentEditingProfile}
            onSave={async (profile) => {
              await restfulClient.browserProfilePatch({
                id: currentEditingProfile.id,
                body: profile,
              })
              setEditProfileWindowVisible(false)
              setCurrentEditingProfile(null)
              message.success('修改浏览器资料成功')
              await browserProfilesRefetch()
            }}
          />
        )}
      </Modal>
      <Tabs
        onChange={(workspaceId) => {
          setCurrentWorkspaceId(parseInt(workspaceId))
        }}
        tabBarExtraContent={{
          left: (
            <Button
              type='link'
              style={{ marginRight: 24 }}
              onClick={() => {
                setNewWorkspaceWindowVisible(true)
              }}
            >
              <PlusOutlined />
              创建工作区
            </Button>
          ),
        }}
      >
        {workspacesLoading ? (
          <Spin />
        ) : (
          workspaces.result.map((workspace) => {
            return <Tabs.TabPane tab={workspace.name} key={workspace.id}></Tabs.TabPane>
          })
        )}
      </Tabs>
      <div>
        <div style={{ display: 'flex' }}>
          {currentWorkspaceId > 0 && (
            <>
              <Button
                type='primary'
                style={{ marginRight: 24 }}
                onClick={() => {
                  setNewBrowserProfileVisible(true)
                }}
              >
                <PlusOutlined />
                创建浏览器资料
              </Button>
              <Button
                style={{ marginRight: 24 }}
                onClick={() => {
                  setImportProfileVisible(true)
                }}
              >
                <PlusOutlined />
                批量导入资料
              </Button>
            </>
          )}
          <Dropdown
            overlay={
              <Menu>
                {actionSpecsLoading ? (
                  <Menu.Item>
                    <Spin />
                  </Menu.Item>
                ) : (
                  actionSpecs.result.map((actionSpec) => {
                    return (
                      <Menu.Item
                        key={actionSpec.name}
                        onClick={() => {
                          restfulClient.browserActionWorkspacePredefinedAction({
                            id: currentWorkspaceId,
                            body: {
                              name: actionSpec.name,
                            },
                          })
                        }}
                      >
                        <a>{actionSpec.displayName}</a>
                      </Menu.Item>
                    )
                  })
                )}
              </Menu>
            }
            placement='bottomLeft'
            arrow
          >
            <Button style={{ marginRight: 24 }}>预定义批量操作</Button>
          </Dropdown>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setNewTabWindowVisible(true)
                  }}
                >
                  <a>打开网址</a>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setClickWindowVisible(true)
                  }}
                >
                  <a>点击指定按钮</a>
                </Menu.Item>
                <Menu.Item
                  onClick={async () => {
                    const ret = await restfulClient.browserActionWorkspaceCloseAllTabs({
                      id: currentWorkspaceId,
                    })
                    message.success(`有 ${ret.data.successCount} 个浏览器关闭了所有标签页`)
                  }}
                >
                  <a>关闭所有标签页</a>
                </Menu.Item>
                <Menu.Item
                  onClick={async () => {
                    await restfulClient.browserActionWorkspaceLaunch({
                      id: currentWorkspaceId,
                    })
                    message.success('开机成功')
                  }}
                >
                  <a>全部开机</a>
                </Menu.Item>
                <Menu.Item
                  onClick={async () => {
                    await restfulClient.browserActionWorkspaceStop({
                      id: currentWorkspaceId,
                    })
                    message.success('关机成功')
                  }}
                >
                  <a>全部关机</a>
                </Menu.Item>
              </Menu>
            }
            placement='bottomLeft'
            arrow
          >
            <Button style={{ marginRight: 24 }}>手动批量操作</Button>
          </Dropdown>
          <Form form={sizeForm}>
            <Form.Item label='卡片宽度' style={{ marginBottom: 0 }}>
              <Form.Item
                name='cardWidth'
                rules={[{ required: true }]}
                initialValue={cardWidth}
                style={{ display: 'inline-block' }}
              >
                <Input
                  placeholder='宽度'
                  onBlur={(e) => {
                    localStorage.setItem(CARD_WIDTH_LOCAL_STORAGE_KEY, e.target.value)
                    setCardWidth(parseInt(e.target.value))
                  }}
                  suffix={
                    <Button onClick={resetCardSize} size={'small'}>
                      重置
                    </Button>
                  }
                />
              </Form.Item>
            </Form.Item>
          </Form>
        </div>

        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {browserProfilesLoading ? (
            <MainLoading />
          ) : browserCount > 0 ? (
            browserProfiles?.data.result.map((browserProfile, index) => {
              return renderCard(browserProfile, index)
            })
          ) : (
            <MainCenter>
              <Empty />
            </MainCenter>
          )}
        </div>
      </div>
    </PageContainer>
  )
}
