import React, { useRef } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table'
import { omit } from 'lodash'
import { restfulClient, Token } from '../../restful'
import { DateTime } from '../../components/date'
import { Button, message, Popconfirm, Tooltip } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'
import { TokenCreateComponent } from './components/token-create.component'

export const TokensPage: React.FC = () => {
  const tableRef = useRef<ActionType>()

  const columns: ProColumns<Token>[] = [
    {
      dataIndex: 'id',
      title: 'id',
    },
    {
      dataIndex: 'name',
      title: '名称',
    },
    {
      dataIndex: 'accessKey',
      title: 'Access Key',
    },
    {
      title: `Access Secret`,
      render: () => {
        return (
          <>
            ********************{' '}
            <Tooltip title='不可逆加密存储，如丢失请吊销重新申请'>
              <QuestionCircleFilled />
            </Tooltip>
          </>
        )
      },
    },
    {
      dataIndex: 'createdAt',
      title: '创建时间',
      render: (_dom, row) => {
        return <DateTime timestamp={row.createdAt} />
      },
    },
    {
      title: '操作',
      render: (_dom, row) => {
        return (
          <Popconfirm
            title='吊销后，Token 即失效，确认吊销吗？'
            onConfirm={async () => {
              try {
                await restfulClient.tokenDelete({
                  id: row.id.toString(),
                })
                tableRef?.current?.reload()
                message.success(`吊销 Token ${row.accessKey} 成功`)
              } catch (e) {
                message.error(e?.response?.data?.message ?? '吊销失败')
              }
            }}
          >
            <Button type='link'>吊销</Button>
          </Popconfirm>
        )
      },
    },
  ]
  return (
    <PageContainer
      title='API Token 管理'
      extra={
        <TokenCreateComponent
          refetch={() => {
            tableRef.current?.reload()
          }}
        />
      }
    >
      <ProTable
        actionRef={tableRef}
        columns={columns}
        rowKey={'id'}
        headerTitle='Token 列表'
        search={false}
        request={async (params) => {
          const { current, pageSize } = params
          const take = pageSize && pageSize > 0 ? pageSize : 10
          const skip = current && current > 0 ? (current - 1) * take : 0
          // const currentFilter = {
          //   fuzzyContent: get(params, 'content') as string,
          // }
          const ret = await restfulClient.tokenList({
            skip,
            take,
            sort: '-createdAt',
            ...omit(params, ['current', 'pageSize']),
            // ...currentFilter,
          })
          return {
            data: ret.data.result,
            success: ret.status === 200,
            total: ret.data.count,
          }
        }}
      />
    </PageContainer>
  )
}
