import React, { useRef, useState } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table'
import { omit } from 'lodash'
import { BrowserProfileAnalysisPopulated, restfulClient } from '../../restful'
import { DateTime } from '../../components/date'
import { Button, message, Popconfirm } from 'antd'

export const BrowserRunningManagementPage: React.FC = () => {
  const tableRef = useRef<ActionType>()
  const [closingAssigneeIds, setClosingAssigneeIds] = useState<Set<number>>(new Set())

  const columns: ProColumns<BrowserProfileAnalysisPopulated>[] = [
    {
      dataIndex: 'username',
      title: '用户名',
    },
    {
      dataIndex: 'launchedCount',
      title: '启动数量',
    },
    {
      dataIndex: 'firstLaunchedAt',
      title: '最早启动',
      render: (_dom, row) => {
        return <DateTime timestamp={row.firstLaunchedAt} />
      },
    },
    {
      dataIndex: 'lastLaunchedAt',
      title: '最后启动',
      render: (_dom, row) => {
        return <DateTime timestamp={row.lastLaunchedAt} />
      },
    },
    {
      title: '操作',
      render: (_dom, row) => {
        return (
          <Popconfirm
            title='确认全部关机吗？'
            onConfirm={async () => {
              try {
                setClosingAssigneeIds((assigneeIds) => {
                  return assigneeIds.add(row.assigneeId)
                })
                await restfulClient.browserProfileStopByUserId({
                  assigneeId: row.assigneeId,
                })
                tableRef?.current?.reload()
                message.success(`用户 ${row.username} 所有机器关闭成功`)
              } catch (e) {
                message.error(e?.response?.data?.message ?? '关机失败')
              } finally {
                setClosingAssigneeIds((assigneeIds) => {
                  assigneeIds.delete(row.assigneeId)
                  return assigneeIds
                })
              }
            }}
          >
            <Button type='link' loading={closingAssigneeIds.has(row.assigneeId)}>
              {closingAssigneeIds.has(row.assigneeId) ? '关机中...' : '全部关机'}
            </Button>
          </Popconfirm>
        )
      },
    },
  ]
  return (
    <PageContainer title='运行中浏览器管理'>
      <ProTable
        actionRef={tableRef}
        columns={columns}
        rowKey={'id'}
        headerTitle='用户浏览器列表'
        search={false}
        request={async (params) => {
          const { current, pageSize } = params
          const take = pageSize && pageSize > 0 ? pageSize : 10
          const skip = current && current > 0 ? (current - 1) * take : 0
          const ret = await restfulClient.browserProfileAnalysis({
            skip,
            take,
            sort: '-createdAt',
            ...omit(params, ['current', 'pageSize']),
          })
          return {
            data: ret.data.result,
            success: ret.status === 200,
            total: ret.data.count,
          }
        }}
      />
    </PageContainer>
  )
}
