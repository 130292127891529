import styled from 'styled-components'
import { Spin } from 'antd'
import React from 'react'

export const MainCenter = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const MainLoading = () => {
  return (
    <MainCenter>
      <Spin size='large' delay={500} />
    </MainCenter>
  )
}
