import React from 'react'
import logo from '../logo.png'
import BasicLayout from '@ant-design/pro-layout'
import { BasicLayoutProps } from '@ant-design/pro-layout/lib/BasicLayout'
import { HeaderViewProps } from '@ant-design/pro-layout/lib/Header'
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { MenuDataItem } from '@ant-design/pro-layout/lib/typings'
import { AppstoreOutlined, UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import antdVars from '../../antd.vars.json'
import { UserPasswordPage } from '../modules/user/password.page'
import { Footer } from '../components/footer'
import { CurrentUserProvider, useUser } from '../modules/login/auth-context'
import { Button } from 'antd'
import { restfulClient } from '../restful'
import { TokensPage } from '../modules/developer/tokens.page'
import { WebHooksPage } from '../modules/developer/webhooks.page'
import { BrowserPage } from '../modules/browser/browser.page'
import { OtpPage } from '../modules/tools/otp.page'
import { BrowserMigration } from '../modules/browser/migrate'
import { BrowserRunningManagementPage } from '../modules/browser/browser-running-management.page'
import { BrowserProfileAssign } from '../modules/browser/browser-profile-assign'

const HeaderContent: React.FC = () => {
  const user = useUser()
  const history = useHistory()
  const logout = async () => {
    history.push('/login')
    await restfulClient.sessionDelete({})
  }
  return (
    <div style={{ float: 'right', marginRight: '24px' }}>
      {user?.username}
      <Button type='link' onClick={logout}>
        退出
      </Button>
    </div>
  )
}
const settings: BasicLayoutProps = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  logo,
  title: 'Wool harvester',
  navTheme: 'light',
  primaryColor: antdVars['primary-color'],
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  pwa: false,
  collapsed: true,
  // onCollapse(collapsed) {
  //   localStorage.setItem('wool.main.menu.collapsed', collapsed ? 'true' : 'false')
  // },
  footerRender: (props: HeaderViewProps, defaultDom: React.ReactNode) => {
    return <Footer />
  },
  headerContentRender: () => {
    return <HeaderContent />
  },
  menuItemRender: (
    item: MenuDataItem & {
      isUrl: boolean
    },
    dom,
  ) => {
    return (
      <Link to={item.path ?? ''} key={item.key ?? item.path}>
        {dom}
      </Link>
    )
  },
  menuDataRender: (): MenuDataItem[] => {
    return [
      {
        key: 'workspace',
        name: '工作区',
        path: '/workspaces',
        icon: <AppstoreOutlined />,
        routes: [
          {
            name: '浏览器',
            key: 'workspaceBrowsers',
            path: '/workspaces/browsers',
          },
        ],
      },
      {
        key: 'user',
        name: '用户设置',
        path: '/user',
        icon: <UserOutlined />,
        routes: [
          {
            name: '修改密码',
            key: 'userPassword',
            path: '/user/password',
          },
        ],
      },
      // {
      //   key: 'developer',
      //   name: '开发者',
      //   path: '/developer',
      //   icon: <ApiOutlined />,
      //   routes: [
      //     {
      //       name: 'API Tokens',
      //       key: 'developerTokens',
      //       path: '/developer/tokens',
      //     },
      //     {
      //       name: 'WebHooks',
      //       key: 'developersWebHooks',
      //       path: '/developer/webhooks',
      //     },
      //   ],
      // },
    ]
  },
}
const StyledBasicLayout = styled.div`
  .ant-pro-global-header-layout-mix {
    background-color: white;
  }

  height: 100vh;

  .ant-pro-global-header-logo {
    h1 {
      color: ${antdVars['primary-color']};
      font-weight: normal;
    }
  }

  .ant-pro-global-header-layout-mix .anticon {
    color: ${antdVars['primary-color']};
  }
`
export const DefaultLayout: React.FC = () => {
  return (
    <StyledBasicLayout>
      <CurrentUserProvider>
        <BasicLayout {...settings}>
          <Switch>
            <Route path='/workspaces/browsers'>
              <BrowserPage />
            </Route>
            <Route path='/browsers/migrate'>
              <BrowserMigration />
            </Route>
            <Route path='/browsers/running-management'>
              <BrowserRunningManagementPage />
            </Route>
            <Route path='/browsers/management'>
              <BrowserProfileAssign />
            </Route>
            <Route path='/user' exact={true}>
              <Redirect to='/user/password' />
            </Route>
            <Route path='/user/password'>
              <UserPasswordPage />
            </Route>
            <Route path='/developer/tokens'>
              <TokensPage />
            </Route>
            <Route path='/developer/webhooks'>
              <WebHooksPage />
            </Route>
            <Route path='/tools/otp'>
              <OtpPage />
            </Route>
          </Switch>
        </BasicLayout>
      </CurrentUserProvider>
    </StyledBasicLayout>
  )
}
