import { User } from '../../restful/generated'
import React, { createContext, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { restfulClient } from '../../restful'
import { Spin } from 'antd'

export const AuthContext: React.Context<{ user?: User }> = createContext({})

export const CurrentUserProvider: React.FC = ({ children }) => {
  const [{ error, loading, data }, refetch] = restfulClient.useSessionGetMine({})
  // useEffect(() => {
  //   refetch()
  // }, [children])
  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size='large' delay={500} />
      </div>
    )
  }
  if (error) {
    return <Redirect to='/login' />
  }
  return <AuthContext.Provider value={{ user: data }}>{children}</AuthContext.Provider>
}
export const useUser = () => {
  const { user } = useContext(AuthContext)
  return user
}
