import React, { useState } from 'react'
import { Button, Input, message, Modal, Result } from 'antd'
import { restfulClient, Token } from '../../../restful'
import { get } from 'lodash'

export const TokenCreateComponent: React.FC<{ refetch?: () => unknown }> = ({ refetch }) => {
  const [visible, setVisible] = useState(false)
  const [name, setName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [createdToken, setCreatedToken] = useState<Token | null>(null)
  const handleCancel = () => {
    setVisible(false)
    setCreatedToken(null)
  }
  const handleOk = async () => {
    setSubmitting(true)
    try {
      if (!name) {
        message.error('请输入 Token 名称')
        return
      }
      const ret = await restfulClient.tokenCreate({
        body: {
          name,
        },
      })
      setCreatedToken(ret.data)
      console.log('refetch', refetch)
      if (refetch) {
        refetch()
      }
    } catch (e) {
      message.error(e?.response?.data?.message ?? '创建失败')
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <>
      <Button type='primary' onClick={() => setVisible(true)}>
        创建 Token
      </Button>
      {createdToken ? (
        <Modal
          title='Token 创建成功'
          visible={visible}
          onOk={handleCancel}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={720}
        >
          <Result
            status='success'
            title='Token 创建成功'
            subTitle={
              <span style={{ color: 'red' }}>
                accessSecret 为单向加密存储，仅在创建时可见，请注意保留，关闭本窗口后无法再取回
              </span>
            }
          >
            <small>ID:</small> <b>{createdToken.id}</b>
            <br />
            <small>AccessKey:</small> <b>{createdToken.accessKey}</b>
            <br />
            <small> AccessSecret：</small> <b>{get(createdToken, 'accessSecret')}</b>
            <br />
            <small> x-token：</small>
            <b>
              {createdToken.accessKey}:{get(createdToken, 'accessSecret')}
            </b>
          </Result>
        </Modal>
      ) : (
        <Modal
          title='创建 Token'
          visible={visible}
          okButtonProps={{ loading: submitting }}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Input
            placeholder='请输入 Token 名称'
            allowClear={true}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onPressEnter={handleOk}
            required={true}
          />
        </Modal>
      )}
    </>
  )
}
