export function readFileAsText(file: Blob): Promise<string | null> {
  const reader = new FileReader()
  reader.readAsText(file)
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(this.result?.toString())
    }
    reader.onerror = function (e) {
      reject(e)
    }
  })
}

export function readAsDataURL(file: Blob): Promise<string | null> {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(this.result?.toString())
    }
    reader.onerror = function (e) {
      reject(e)
    }
  })
}
