import React, { useState } from 'react'
import { Card, Form, Input } from 'antd'
import { OTPInput } from '../browser/vars-viewer.page'

export const OtpPage: React.FC = () => {
  const [code, setCode] = useState('')
  return (
    <Card title='一次性密码生成工具'>
      <Form.Item label='一次性密码' style={{ marginBottom: 6 }}>
        <Input
          defaultValue={code}
          onBlur={(e) => {
            setCode(e.target.value.trim())
          }}
        />
      </Form.Item>
      <Form.Item label='一次性密码' style={{ marginBottom: 6 }}>
        <OTPInput code={code} />
      </Form.Item>
    </Card>
  )
}
