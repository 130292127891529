import { get } from 'lodash'

const canvas = document.createElement('canvas')
const gl = canvas.getContext('webgl')
const debugInfo = gl?.getExtension('WEBGL_debug_renderer_info')

export const browserInfo = {
  userAgent: navigator.userAgent,
  platform: navigator.platform,
  hardwareConcurrency: navigator.hardwareConcurrency,
  deviceMemory: get(navigator, 'deviceMemory'),
  webglVendor: debugInfo && gl?.getParameter(debugInfo?.UNMASKED_VENDOR_WEBGL),
  webglRenderer: debugInfo && gl?.getParameter(debugInfo?.UNMASKED_RENDERER_WEBGL),
} as const
