import React, { useState } from 'react'
import { restfulClient } from '../../restful'
import { DebounceSelect } from '../../components/debounce-select.component'

declare type UserOption = number //{ label: string; value: number }
export const UserSelectComponent = ({
  value,
  onChange,
  style,
}: {
  value?: UserOption
  onChange?: (uid: UserOption) => void
  style?: React.CSSProperties
}) => {
  const [selectedValue, setSelectedValue] = useState<UserOption | undefined>(value)
  return (
    <DebounceSelect<{ label: string; value: number }>
      value={selectedValue}
      placeholder='Select users'
      fetchOptions={async (q) => {
        const ret = await restfulClient.userAdminList({ take: 20, fuzzyUsername: q || undefined })
        return ret.data.result.map((row) => {
          return {
            label: row.username,
            value: row.id,
          }
        })
      }}
      onChange={(v) => {
        setSelectedValue(v)
        if (onChange) {
          onChange(v)
        }
      }}
      style={{ width: '100%', ...style }}
    />
  )
}
