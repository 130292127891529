import { PageContainer } from '@ant-design/pro-layout'
import React from 'react'
import { Button, Card, Form, Input, message } from 'antd'
import { formItemLayout, submitFormLayout } from '../../components/form-layout'
import { Callbacks, Rule } from 'rc-field-form/lib/interface'
import { UserModifyPasswordInput } from '../../restful/generated'
import { restfulClient } from '../../restful'

const passwordRules: Rule[] = [
  {
    required: true,
    message: '密码不可为空',
  },
  {
    min: 6,
    max: 20,
    message: '密码长度在 6~20 之间',
  },
]
export const UserPasswordPage: React.FC = () => {
  const [form] = Form.useForm()
  const onFinish: Callbacks<UserModifyPasswordInput>['onFinish'] = async (values) => {
    try {
      await restfulClient.userModifyPassword({
        body: {
          password: values.password,
          newPassword: values.newPassword,
        },
      })
      form.resetFields()
      message.success('修改成功')
    } catch (e) {
      message.error(e?.response?.data?.message ?? '修改失败')
    }
  }
  return (
    <PageContainer title='修改密码'>
      <Card>
        <Form form={form} onFinish={onFinish}>
          <Form.Item label='原密码' name='password' {...formItemLayout} rules={passwordRules}>
            <Input type='password' />
          </Form.Item>
          <Form.Item label='新密码' name='newPassword' {...formItemLayout} rules={passwordRules}>
            <Input type='password' />
          </Form.Item>
          <Form.Item
            label='新密码确认'
            name='newPasswordConfirm'
            {...formItemLayout}
            dependencies={['newPassword']}
            rules={passwordRules.concat(({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('两次密码不一致')
              },
            }))}
          >
            <Input type='password' />
          </Form.Item>
          <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
            <Button type='primary' htmlType='submit'>
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageContainer>
  )
}
