import React, { ErrorInfo } from 'react'
import { message, Result } from 'antd'
import AlertErrorBoundary from 'antd/es/alert/ErrorBoundary'

interface ErrorBoundaryProps {
  message?: React.ReactNode
  description?: React.ReactNode
}

export class AppErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; props: ErrorBoundaryProps; error?: Error; errorInfo?: ErrorInfo }
> {
  constructor(props: { message: string; description: string }) {
    super(props)
    this.state = { hasError: false, props }
  }

  static getDerivedStateFromError(error: Error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // 你同样可以将错误日志上报给服务器
    // logErrorToMyService(error, errorInfo)
    // eslint-disable-next-line no-console
    console.error('页面发生错误')
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      // return <h1>Something went wrong.</h1>
      const errorInfo = (
        <div style={{ maxWidth: '900px', textAlign: 'left', margin: '20px auto' }}>
          <AlertErrorBoundary {...this.state.props} />
        </div>
      )
      return (
        <Result status='error' title='Something went wrong' subTitle={this.state.error?.message} extra={errorInfo} />
      )
    }

    return this.props.children
  }
}
