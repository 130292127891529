let sequence = 0

/**
 * 在当前浏览器范围内生成唯一 ID
 */
export function localUniqueId() {
  return ++sequence
}

interface Dictionary<T> {
  [index: string]: T
}

interface NumericDictionary<T> {
  [index: number]: T
}

export function deepEntries<T = unknown>(object?: { [s: string]: T } | ArrayLike<T>, baseKeys = ''): [string, T][] {
  if (!object) {
    return []
  }
  const originalEntries = Object.entries(object)
  const entries: [string, T][] = []
  for (const item of originalEntries) {
    const [key, value] = item
    const wrappedKey = `${baseKeys ? `${baseKeys}.` : ''}${key}`
    if (value && typeof value === 'object') {
      entries.push(
        ...((deepEntries((value as unknown) as { [s: string]: unknown }, wrappedKey) as unknown) as [string, T][]),
      )
    } else {
      entries.push([wrappedKey, value])
    }
  }

  return entries
}
