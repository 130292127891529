import React, { useState } from 'react'
import Dragger from 'antd/es/upload/Dragger'
import { InboxOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'
import { readFileAsText } from '../../utils/io'
import { Button, List, message } from 'antd'
import csvParse from 'csv-parse'
import { BrowserProfile, restfulClient } from '../../restful'
import { set } from 'lodash'
import { browserInfo } from './defines'

export interface BrowserProfileImporterProps {
  workspaceId: number
}

declare type ImportRow = {
  status: 'success' | 'failed' | 'pending'
  profile: BrowserProfile
  message?: string
}
export const BrowserProfileImporter: React.FC<BrowserProfileImporterProps> = ({ workspaceId }) => {
  const [inputs, setInputs] = useState<Array<ImportRow>>([])
  const [fileList, setFileList] = useState<RcFile[]>([])
  const processFile = (file: RcFile) => {
    readFileAsText(file)
      .then((ret) => {
        if (!ret) {
          message.error('文件不能为空')
          return
        }
        csvParse(ret.trim(), { columns: true }, (err, records) => {
          console.log('err', err)
          console.log('records', records)

          if (err) {
            message.error(err)
            console.error(err)
            return
          }
          setInputs(function () {
            const thisInputs: ImportRow[] = []
            console.log('thisInputs', thisInputs)
            for (const record of records) {
              const input = {
                ...browserInfo,
                workspaceId,
              }
              for (const [key, value] of Object.entries(record)) {
                if (!key) {
                  continue
                }
                set(input, key, value)
              }
              thisInputs.push({
                status: 'pending',
                profile: input as BrowserProfile,
              })
            }
            return thisInputs
          })
        })
      })
      .catch((e) => {
        message.error(e)
      })
      .finally(() => {
        // setFileProcessing((prev) => {
        //   return prev - 1
        // })
      })
  }
  const beforeUpload = (file: RcFile) => {
    setInputs([])
    processFile(file)
    setFileList([])
    return false
  }
  const startCreate = () => {
    inputs.map(async (input) => {
      let status: 'success' | 'failed' = 'success'
      let message = '添加成功'
      try {
        await restfulClient.browserProfileCreate({
          body: input.profile,
        })
      } catch (e) {
        status = 'failed'
        message = JSON.stringify(e.response.data)
      }

      setInputs((thisInputs) => {
        return thisInputs.map((thisInput) => {
          if (thisInput.profile.name === input.profile.name) {
            thisInput.status = status
            thisInput.message = message
          }
          return thisInput
        })
      })
    })
  }
  return (
    <div>
      <Dragger beforeUpload={beforeUpload} multiple={false} fileList={fileList} accept='.csv'>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>点击或拖拽文件</p>
      </Dragger>
      {inputs.length > 0 && (
        <Button type='primary' onClick={startCreate}>
          开始导入
        </Button>
      )}
      <div style={{ height: 400, overflow: 'scroll' }}>
        <List
          itemLayout='horizontal'
          dataSource={inputs}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta avatar={item.status} title={item.profile.name} description={item.message} />
            </List.Item>
          )}
        />
      </div>
    </div>
  )
}
