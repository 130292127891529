import React from 'react'
import ProForm, { ProFormText } from '@ant-design/pro-form'
import { PageContainer } from '@ant-design/pro-layout'
import { Card } from 'antd'

const urlErrorMessage = '请输入正确的回调地址'
export const WebHooksPage: React.FC = () => {
  return (
    <PageContainer title='WebHooks'>
      <Card>
        <ProForm>
          <ProFormText
            width='xl'
            name='smsReport'
            label='短信状态报告回调地址'
            rules={[
              {
                type: 'url',
                message: urlErrorMessage,
              },
            ]}
          />
          <ProFormText
            width='xl'
            name='mmsReport'
            label='视频短信状态报告回调地址'
            rules={[
              {
                type: 'url',
                message: urlErrorMessage,
              },
            ]}
          />
          <ProFormText
            width='xl'
            name='mmsTemplateReview'
            label='视频短信模板审核回调地址'
            rules={[
              {
                type: 'url',
                message: urlErrorMessage,
              },
            ]}
          />
        </ProForm>
      </Card>
    </PageContainer>
  )
}
