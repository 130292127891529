/* tslint:disable */
/* eslint-disable */
/**
 * TwoRing RESTful APIs
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import { RestConfiguration } from "./configuration";
import { AxiosResponse, AxiosRequestConfig } from 'axios'
import useAxios from 'axios-hooks'
import axios from 'axios'

interface ModelObject {}
declare type Unknown = unknown
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 * 
 * @export
 * @interface BatchOperationResponse
 */
export interface BatchOperationResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchOperationResponse
     */
    successCount: number;
    /**
     * 
     * @type {Array<ProfileOperationResponse>}
     * @memberof BatchOperationResponse
     */
    responses: Array<ProfileOperationResponse>;
}

/**
 * 
 * @export
 * @interface BrowserProfile
 */
export interface BrowserProfile {
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    language: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    hardwareConcurrency: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    deviceMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    webglVendor: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    webglRenderer: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    assigneeId: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    serverEndpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfile
     */
    noVncEndpoint?: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfile
     */
    lastLaunchedAt?: number;
    /**
     * 
     * @type {any}
     * @memberof BrowserProfile
     */
    vars: any;
}

/**
 * 
 * @export
 * @interface BrowserProfileAnalysisPaginatedResult
 */
export interface BrowserProfileAnalysisPaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof BrowserProfileAnalysisPaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<BrowserProfileAnalysisPopulated>}
     * @memberof BrowserProfileAnalysisPaginatedResult
     */
    result: Array<BrowserProfileAnalysisPopulated>;
}

/**
 * 
 * @export
 * @interface BrowserProfileAnalysisPopulated
 */
export interface BrowserProfileAnalysisPopulated {
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileAnalysisPopulated
     */
    assigneeId: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileAnalysisPopulated
     */
    lastLaunchedAt: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileAnalysisPopulated
     */
    launchedCount: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileAnalysisPopulated
     */
    firstLaunchedAt: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileAnalysisPopulated
     */
    username: string;
}

/**
 * 
 * @export
 * @interface BrowserProfileCreateInput
 */
export interface BrowserProfileCreateInput {
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    webglVendor: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    webglRenderer: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileCreateInput
     */
    hardwareConcurrency: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileCreateInput
     */
    deviceMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfileCreateInput
     */
    assigneeId?: number;
    /**
     * 
     * @type {any}
     * @memberof BrowserProfileCreateInput
     */
    vars?: any;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileCreateInput
     */
    status?: string;
}

/**
 * 
 * @export
 * @interface BrowserProfileMigrateInput
 */
export interface BrowserProfileMigrateInput {
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileMigrateInput
     */
    webglVendor: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfileMigrateInput
     */
    webglRenderer: string;
}

/**
 * 
 * @export
 * @interface BrowserProfileMoveInput
 */
export interface BrowserProfileMoveInput {
    /**
     * profile ids
     * @type {Array<number>}
     * @memberof BrowserProfileMoveInput
     */
    ids: Array<number>;
    /**
     * 工作组 ID
     * @type {number}
     * @memberof BrowserProfileMoveInput
     */
    workspaceId: number;
}

/**
 * 
 * @export
 * @interface BrowserProfilePaginatedResult
 */
export interface BrowserProfilePaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof BrowserProfilePaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<BrowserProfilePopulated>}
     * @memberof BrowserProfilePaginatedResult
     */
    result: Array<BrowserProfilePopulated>;
}

/**
 * 
 * @export
 * @interface BrowserProfilePopulated
 */
export interface BrowserProfilePopulated {
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    language: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    hardwareConcurrency: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    deviceMemory?: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    webglVendor: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    webglRenderer: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    assigneeId: number;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    serverEndpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserProfilePopulated
     */
    noVncEndpoint?: string;
    /**
     * 
     * @type {number}
     * @memberof BrowserProfilePopulated
     */
    lastLaunchedAt?: number;
    /**
     * 
     * @type {any}
     * @memberof BrowserProfilePopulated
     */
    vars: any;
    /**
     * 
     * @type {}
     * @memberof BrowserProfilePopulated
     */
    creator?: ;
    /**
     * 
     * @type {TargetEntity}
     * @memberof BrowserProfilePopulated
     */
    workspace?: TargetEntity;
    /**
     * 
     * @type {TargetEntity}
     * @memberof BrowserProfilePopulated
     */
    assignee?: TargetEntity;
}

/**
 * 
 * @export
 * @interface ClickInput
 */
export interface ClickInput {
    /**
     * 
     * @type {string}
     * @memberof ClickInput
     */
    selector: string;
}

/**
 * 
 * @export
 * @interface NewTabInput
 */
export interface NewTabInput {
    /**
     * 
     * @type {string}
     * @memberof NewTabInput
     */
    url: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PossibleFeatures {
    User = 'user',
    UserCreate = 'userCreate',
    UserDelete = 'userDelete',
    SuperAdmin = 'superAdmin',
    BrowserProfileManage = 'browserProfileManage'
}

/**
 * 
 * @export
 * @interface PreDefinedActionInput
 */
export interface PreDefinedActionInput {
    /**
     * 
     * @type {string}
     * @memberof PreDefinedActionInput
     */
    name: string;
}

/**
 * 
 * @export
 * @interface PredefinedAction
 */
export interface PredefinedAction {
    /**
     * 
     * @type {string}
     * @memberof PredefinedAction
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedAction
     */
    displayName: string;
}

/**
 * 
 * @export
 * @interface PredefinedActionList
 */
export interface PredefinedActionList {
    /**
     * 
     * @type {Array<PredefinedAction>}
     * @memberof PredefinedActionList
     */
    result: Array<PredefinedAction>;
    /**
     * 
     * @type {number}
     * @memberof PredefinedActionList
     */
    count: number;
}

/**
 * 
 * @export
 * @interface ProfileOperationResponse
 */
export interface ProfileOperationResponse {
    /**
     * 
     * @type {number}
     * @memberof ProfileOperationResponse
     */
    profileId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOperationResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileOperationResponse
     */
    message: string;
}

/**
 * 
 * @export
 * @interface SessionCreateInput
 */
export interface SessionCreateInput {
    /**
     * 用户名
     * @type {string}
     * @memberof SessionCreateInput
     */
    username: string;
    /**
     * 密码
     * @type {string}
     * @memberof SessionCreateInput
     */
    password: string;
}

/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    accessKey: string;
}

/**
 * 
 * @export
 * @interface TokenCreateInput
 */
export interface TokenCreateInput {
    /**
     * Token 名称
     * @type {string}
     * @memberof TokenCreateInput
     */
    name: string;
}

/**
 * 
 * @export
 * @interface TokenPaginatedResult
 */
export interface TokenPaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof TokenPaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<TokenPopulated>}
     * @memberof TokenPaginatedResult
     */
    result: Array<TokenPopulated>;
}

/**
 * 
 * @export
 * @interface TokenPopulated
 */
export interface TokenPopulated {
    /**
     * 
     * @type {number}
     * @memberof TokenPopulated
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPopulated
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPopulated
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPopulated
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPopulated
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenPopulated
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TokenPopulated
     */
    accessKey: string;
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isSuperAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    roleId: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    lastLoggedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastLoggedIn?: string;
}

/**
 * 
 * @export
 * @interface UserAdminCreateUserInput
 */
export interface UserAdminCreateUserInput {
    /**
     * 用户名
     * @type {string}
     * @memberof UserAdminCreateUserInput
     */
    username: string;
    /**
     * 密码
     * @type {string}
     * @memberof UserAdminCreateUserInput
     */
    password: string;
    /**
     * 用户角色
     * @type {number}
     * @memberof UserAdminCreateUserInput
     */
    roleId: number;
}

/**
 * 
 * @export
 * @interface UserAdminPatchUserInput
 */
export interface UserAdminPatchUserInput {
    /**
     * 用户名
     * @type {string}
     * @memberof UserAdminPatchUserInput
     */
    username: string;
    /**
     * 密码
     * @type {string}
     * @memberof UserAdminPatchUserInput
     */
    password: string;
    /**
     * 角色 ID
     * @type {number}
     * @memberof UserAdminPatchUserInput
     */
    roleId: number;
}

/**
 * 
 * @export
 * @interface UserModifyPasswordInput
 */
export interface UserModifyPasswordInput {
    /**
     * 原密码
     * @type {string}
     * @memberof UserModifyPasswordInput
     */
    password: string;
    /**
     * 新密码
     * @type {string}
     * @memberof UserModifyPasswordInput
     */
    newPassword: string;
}

/**
 * 
 * @export
 * @interface UserPaginatedResult
 */
export interface UserPaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof UserPaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<UserPopulated>}
     * @memberof UserPaginatedResult
     */
    result: Array<UserPopulated>;
}

/**
 * 
 * @export
 * @interface UserPopulated
 */
export interface UserPopulated {
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPopulated
     */
    username: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPopulated
     */
    isSuperAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    roleId: number;
    /**
     * 
     * @type {number}
     * @memberof UserPopulated
     */
    lastLoggedAt?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPopulated
     */
    lastLoggedIn?: string;
    /**
     * 
     * @type {}
     * @memberof UserPopulated
     */
    creator?: ;
}

/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRole
     */
    features: Array<string>;
}

/**
 * 
 * @export
 * @interface UserRoleCreateInput
 */
export interface UserRoleCreateInput {
    /**
     * 
     * @type {string}
     * @memberof UserRoleCreateInput
     */
    name: string;
    /**
     * 
     * @type {Array<PossibleFeatures>}
     * @memberof UserRoleCreateInput
     */
    features: Array<PossibleFeatures>;
}

/**
 * 
 * @export
 * @interface UserRoleFeatures
 */
export interface UserRoleFeatures {
    /**
     * 
     * @type {Array<PossibleFeatures>}
     * @memberof UserRoleFeatures
     */
    features: Array<PossibleFeatures>;
}

/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    assigneeId: number;
}

/**
 * 
 * @export
 * @interface WorkspaceCreateInput
 */
export interface WorkspaceCreateInput {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceCreateInput
     */
    name: string;
}

/**
 * 
 * @export
 * @interface WorkspacePaginatedResult
 */
export interface WorkspacePaginatedResult {
    /**
     * 数据库中的条数
     * @type {number}
     * @memberof WorkspacePaginatedResult
     */
    count: number;
    /**
     * 结果数组
     * @type {Array<WorkspacePopulated>}
     * @memberof WorkspacePaginatedResult
     */
    result: Array<WorkspacePopulated>;
}

/**
 * 
 * @export
 * @interface WorkspacePopulated
 */
export interface WorkspacePopulated {
    /**
     * 
     * @type {number}
     * @memberof WorkspacePopulated
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspacePopulated
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspacePopulated
     */
    updatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspacePopulated
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspacePopulated
     */
    updatedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspacePopulated
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WorkspacePopulated
     */
    assigneeId: number;
    /**
     * 
     * @type {}
     * @memberof WorkspacePopulated
     */
    creator?: ;
}

export class RESTClient {
      constructor(protected configuration: RestConfiguration = {}) {

      }

        /**
         * AxiosRequestOptions generator for userAdminCreate
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userAdminCreateAxiosOptionsCreator(input: { body: UserAdminCreateUserInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("UserAdminCreateUserInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/users
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userAdminCreate<T = User>(input: {
        body: UserAdminCreateUserInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userAdminCreateAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/users
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserAdminCreate<T = User>(input: {
        /**
         * 
         */
        body: UserAdminCreateUserInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userAdminCreateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userAdminGet
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userAdminGetAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/users/{id}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userAdminGet<T = User>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userAdminGetAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/users/{id}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserAdminGet<T = User>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userAdminGetAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userAdminList
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userAdminListAxiosOptionsCreator(input: { id?: number, username?: string, fuzzyUsername?: string, ids?: string, skip?: number, take?: number, sort?: string, columns?: Array<string>, populations?: Array<string>,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.id !== undefined) {
                localVarQueryParameter['id'] = input.id;
            }

            if (input.username !== undefined) {
                localVarQueryParameter['username'] = input.username;
            }

            if (input.fuzzyUsername !== undefined) {
                localVarQueryParameter['fuzzyUsername'] = input.fuzzyUsername;
            }

            if (input.ids !== undefined) {
                localVarQueryParameter['ids'] = input.ids;
            }

            if (input.skip !== undefined) {
                localVarQueryParameter['skip'] = input.skip;
            }

            if (input.take !== undefined) {
                localVarQueryParameter['take'] = input.take;
            }

            if (input.sort !== undefined) {
                localVarQueryParameter['sort'] = input.sort;
            }

            if (input.columns) {
              localVarQueryParameter['columns'] = input.columns.join(COLLECTION_FORMATS.csv);
            }

            if (input.populations) {
              localVarQueryParameter['populations'] = input.populations.join(COLLECTION_FORMATS.csv);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/users
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userAdminList<T = UserPaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 用户名
         */
        username?: string,
              /**
         * 用户名 (模糊匹配)
         */
        fuzzyUsername?: string,
              /**
         * id 数组
         */
        ids?: string,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, username, isSuperAdmin, roleId, lastLoggedAt, lastLoggedIn
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userAdminListAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/users
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserAdminList<T = UserPaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 用户名
         */
        username?: string,
              /**
         * 用户名 (模糊匹配)
         */
        fuzzyUsername?: string,
              /**
         * id 数组
         */
        ids?: string,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, username, isSuperAdmin, roleId, lastLoggedAt, lastLoggedIn
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userAdminListAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userAdminPatch
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userAdminPatchAxiosOptionsCreator(input: { body: UserAdminPatchUserInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'PATCH',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("UserAdminPatchUserInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/users/{id}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userAdminPatch<T = User>(input: {
        body: UserAdminPatchUserInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userAdminPatchAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/users/{id}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserAdminPatch<T = User>(input: {
        /**
         * 
         */
        body: UserAdminPatchUserInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userAdminPatchAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userRoleAdminCreate
         * 创建角色
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userRoleAdminCreateAxiosOptionsCreator(input: { body: UserRoleCreateInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/roles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("UserRoleCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 创建角色
     * @url /api/v1/admin/roles
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userRoleAdminCreate<T = UserRole>(input: {
        body: UserRoleCreateInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userRoleAdminCreateAxiosOptionsCreator(input, options));
    }
    /**
     * 创建角色
     * @url /api/v1/admin/roles
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserRoleAdminCreate<T = UserRole>(input: {
        /**
         * 
         */
        body: UserRoleCreateInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userRoleAdminCreateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userRoleAdminFeatures
         * 获取 features 清单
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userRoleAdminFeaturesAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/roles/features`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 获取 features 清单
     * @url /api/v1/admin/roles/features
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userRoleAdminFeatures<T = UserRoleFeatures>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userRoleAdminFeaturesAxiosOptionsCreator(input, options));
    }
    /**
     * 获取 features 清单
     * @url /api/v1/admin/roles/features
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserRoleAdminFeatures<T = UserRoleFeatures>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userRoleAdminFeaturesAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userRoleAdminPatch
         * 更新角色
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userRoleAdminPatchAxiosOptionsCreator(input: { body: UserRoleCreateInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'PATCH',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("UserRoleCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 更新角色
     * @url /api/v1/admin/roles/{id}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userRoleAdminPatch<T = UserRole>(input: {
        body: UserRoleCreateInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userRoleAdminPatchAxiosOptionsCreator(input, options));
    }
    /**
     * 更新角色
     * @url /api/v1/admin/roles/{id}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag AdminApi
     */
     public useUserRoleAdminPatch<T = UserRole>(input: {
        /**
         * 
         */
        body: UserRoleCreateInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userRoleAdminPatchAxiosOptionsCreator(input, options));
    }

        /**
         * AxiosRequestOptions generator for browserActionGetPredefined
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionGetPredefinedAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionGetPredefined<T = PredefinedActionList>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionGetPredefinedAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionGetPredefined<T = PredefinedActionList>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionGetPredefinedAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionLaunch
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionLaunchAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/{id}/launch`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/{id}/launch
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionLaunch<T = unknown>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionLaunchAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/{id}/launch
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionLaunch<T = unknown>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionLaunchAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionPredefinedAction
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionPredefinedActionAxiosOptionsCreator(input: { body: PreDefinedActionInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/{id}/predefined`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("PreDefinedActionInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/{id}/predefined
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionPredefinedAction<T = unknown>(input: {
        body: PreDefinedActionInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionPredefinedActionAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/{id}/predefined
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionPredefinedAction<T = unknown>(input: {
        /**
         * 
         */
        body: PreDefinedActionInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionPredefinedActionAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionStop
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionStopAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/{id}/stop`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/{id}/stop
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionStop<T = unknown>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionStopAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/{id}/stop
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionStop<T = unknown>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionStopAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionWorkspaceClick
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionWorkspaceClickAxiosOptionsCreator(input: { body: ClickInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/workspaces/{id}/click`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("ClickInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/click
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionWorkspaceClick<T = BatchOperationResponse>(input: {
        body: ClickInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionWorkspaceClickAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/click
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionWorkspaceClick<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        body: ClickInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionWorkspaceClickAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionWorkspaceCloseAllTabs
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionWorkspaceCloseAllTabsAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/workspaces/{id}/close-all-tabs`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/close-all-tabs
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionWorkspaceCloseAllTabs<T = BatchOperationResponse>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionWorkspaceCloseAllTabsAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/close-all-tabs
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionWorkspaceCloseAllTabs<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionWorkspaceCloseAllTabsAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionWorkspaceLaunch
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionWorkspaceLaunchAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/workspaces/{id}/launch`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/launch
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionWorkspaceLaunch<T = BatchOperationResponse>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionWorkspaceLaunchAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/launch
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionWorkspaceLaunch<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionWorkspaceLaunchAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionWorkspaceNewTab
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionWorkspaceNewTabAxiosOptionsCreator(input: { body: NewTabInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/workspaces/{id}/new-tab`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("NewTabInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/new-tab
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionWorkspaceNewTab<T = BatchOperationResponse>(input: {
        body: NewTabInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionWorkspaceNewTabAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/new-tab
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionWorkspaceNewTab<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        body: NewTabInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionWorkspaceNewTabAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionWorkspacePredefinedAction
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionWorkspacePredefinedActionAxiosOptionsCreator(input: { body: PreDefinedActionInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/workspaces/{id}/predefined`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("PreDefinedActionInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/predefined
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionWorkspacePredefinedAction<T = unknown>(input: {
        body: PreDefinedActionInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionWorkspacePredefinedActionAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/predefined
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionWorkspacePredefinedAction<T = unknown>(input: {
        /**
         * 
         */
        body: PreDefinedActionInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionWorkspacePredefinedActionAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserActionWorkspaceStop
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserActionWorkspaceStopAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-actions/workspaces/{id}/stop`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/stop
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserActionWorkspaceStop<T = BatchOperationResponse>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserActionWorkspaceStopAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-actions/workspaces/{id}/stop
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserActionWorkspaceStop<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserActionWorkspaceStopAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileAdminList
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileAdminListAxiosOptionsCreator(input: { id?: number, fuzzyName?: string, workspaceId?: number, assigneeId?: number, status?: string, skip?: number, take?: number, sort?: string, columns?: Array<string>, populations?: Array<string>,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/browser-profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.id !== undefined) {
                localVarQueryParameter['id'] = input.id;
            }

            if (input.fuzzyName !== undefined) {
                localVarQueryParameter['fuzzyName'] = input.fuzzyName;
            }

            if (input.workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = input.workspaceId;
            }

            if (input.assigneeId !== undefined) {
                localVarQueryParameter['assigneeId'] = input.assigneeId;
            }

            if (input.status !== undefined) {
                localVarQueryParameter['status'] = input.status;
            }

            if (input.skip !== undefined) {
                localVarQueryParameter['skip'] = input.skip;
            }

            if (input.take !== undefined) {
                localVarQueryParameter['take'] = input.take;
            }

            if (input.sort !== undefined) {
                localVarQueryParameter['sort'] = input.sort;
            }

            if (input.columns) {
              localVarQueryParameter['columns'] = input.columns.join(COLLECTION_FORMATS.csv);
            }

            if (input.populations) {
              localVarQueryParameter['populations'] = input.populations.join(COLLECTION_FORMATS.csv);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/browser-profiles
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileAdminList<T = BrowserProfilePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 名称 (模糊匹配)
         */
        fuzzyName?: string,
              /**
         * 工作区 ID
         */
        workspaceId?: number,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 工作区 ID
         */
        status?: string,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, userAgent, platform, timezone, language, hardwareConcurrency, deviceMemory, webglVendor, webglRenderer, country, assigneeId, workspaceId, status, serverEndpoint, noVncEndpoint, lastLaunchedAt, vars
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileAdminListAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/browser-profiles
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileAdminList<T = BrowserProfilePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 名称 (模糊匹配)
         */
        fuzzyName?: string,
              /**
         * 工作区 ID
         */
        workspaceId?: number,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 工作区 ID
         */
        status?: string,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, userAgent, platform, timezone, language, hardwareConcurrency, deviceMemory, webglVendor, webglRenderer, country, assigneeId, workspaceId, status, serverEndpoint, noVncEndpoint, lastLaunchedAt, vars
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileAdminListAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileAdminMoveToWorkspace
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileAdminMoveToWorkspaceAxiosOptionsCreator(input: { body: BrowserProfileMoveInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/browser-profiles/move`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("BrowserProfileMoveInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/browser-profiles/move
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileAdminMoveToWorkspace<T = unknown>(input: {
        body: BrowserProfileMoveInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileAdminMoveToWorkspaceAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/browser-profiles/move
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileAdminMoveToWorkspace<T = unknown>(input: {
        /**
         * 
         */
        body: BrowserProfileMoveInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileAdminMoveToWorkspaceAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileAnalysis
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileAnalysisAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles/analysis`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles/analysis
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileAnalysis<T = BrowserProfileAnalysisPaginatedResult>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileAnalysisAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles/analysis
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileAnalysis<T = BrowserProfileAnalysisPaginatedResult>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileAnalysisAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileCreate
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileCreateAxiosOptionsCreator(input: { body: BrowserProfileCreateInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("BrowserProfileCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileCreate<T = BrowserProfile>(input: {
        body: BrowserProfileCreateInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileCreateAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileCreate<T = BrowserProfile>(input: {
        /**
         * 
         */
        body: BrowserProfileCreateInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileCreateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileList
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileListAxiosOptionsCreator(input: { id?: number, fuzzyName?: string, workspaceId?: number, assigneeId?: number, status?: string, skip?: number, take?: number, sort?: string, columns?: Array<string>, populations?: Array<string>,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.id !== undefined) {
                localVarQueryParameter['id'] = input.id;
            }

            if (input.fuzzyName !== undefined) {
                localVarQueryParameter['fuzzyName'] = input.fuzzyName;
            }

            if (input.workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = input.workspaceId;
            }

            if (input.assigneeId !== undefined) {
                localVarQueryParameter['assigneeId'] = input.assigneeId;
            }

            if (input.status !== undefined) {
                localVarQueryParameter['status'] = input.status;
            }

            if (input.skip !== undefined) {
                localVarQueryParameter['skip'] = input.skip;
            }

            if (input.take !== undefined) {
                localVarQueryParameter['take'] = input.take;
            }

            if (input.sort !== undefined) {
                localVarQueryParameter['sort'] = input.sort;
            }

            if (input.columns) {
              localVarQueryParameter['columns'] = input.columns.join(COLLECTION_FORMATS.csv);
            }

            if (input.populations) {
              localVarQueryParameter['populations'] = input.populations.join(COLLECTION_FORMATS.csv);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileList<T = BrowserProfilePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 名称 (模糊匹配)
         */
        fuzzyName?: string,
              /**
         * 工作区 ID
         */
        workspaceId?: number,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 工作区 ID
         */
        status?: string,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, userAgent, platform, timezone, language, hardwareConcurrency, deviceMemory, webglVendor, webglRenderer, country, assigneeId, workspaceId, status, serverEndpoint, noVncEndpoint, lastLaunchedAt, vars
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileListAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileList<T = BrowserProfilePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 名称 (模糊匹配)
         */
        fuzzyName?: string,
              /**
         * 工作区 ID
         */
        workspaceId?: number,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 工作区 ID
         */
        status?: string,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, userAgent, platform, timezone, language, hardwareConcurrency, deviceMemory, webglVendor, webglRenderer, country, assigneeId, workspaceId, status, serverEndpoint, noVncEndpoint, lastLaunchedAt, vars
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileListAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileMigrate
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileMigrateAxiosOptionsCreator(input: { body: BrowserProfileMigrateInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles/migrate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("BrowserProfileMigrateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles/migrate
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileMigrate<T = BatchOperationResponse>(input: {
        body: BrowserProfileMigrateInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileMigrateAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles/migrate
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileMigrate<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        body: BrowserProfileMigrateInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileMigrateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfilePatch
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfilePatchAxiosOptionsCreator(input: { body: BrowserProfileCreateInput, id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("BrowserProfileCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles/{id}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfilePatch<T = BrowserProfile>(input: {
        body: BrowserProfileCreateInput,
              id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfilePatchAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles/{id}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfilePatch<T = BrowserProfile>(input: {
        /**
         * 
         */
        body: BrowserProfileCreateInput,
              /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfilePatchAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for browserProfileStopByUserId
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileStopByUserIdAxiosOptionsCreator(input: { assigneeId: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles/stop-by-assignee-id/{assigneeId}`
                .replace(`{${"assigneeId"}}`, encodeURIComponent(String(input.assigneeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles/stop-by-assignee-id/{assigneeId}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileStopByUserId<T = BatchOperationResponse>(input: {
        assigneeId: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileStopByUserIdAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles/stop-by-assignee-id/{assigneeId}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useBrowserProfileStopByUserId<T = BatchOperationResponse>(input: {
        /**
         * 
         */
        assigneeId: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileStopByUserIdAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for proxySubscriptionsInfo
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected proxySubscriptionsInfoAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/proxies/default/subscriptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/proxies/default/subscriptions
     * @param input
     * @param {*} [options] Override http request option.
     */
    public proxySubscriptionsInfo<T = unknown>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.proxySubscriptionsInfoAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/proxies/default/subscriptions
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useProxySubscriptionsInfo<T = unknown>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.proxySubscriptionsInfoAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for userModifyPassword
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected userModifyPasswordAxiosOptionsCreator(input: { body: UserModifyPasswordInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/users/modify-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("UserModifyPasswordInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/users/modify-password
     * @param input
     * @param {*} [options] Override http request option.
     */
    public userModifyPassword<T = User>(input: {
        body: UserModifyPasswordInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.userModifyPasswordAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/users/modify-password
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useUserModifyPassword<T = User>(input: {
        /**
         * 
         */
        body: UserModifyPasswordInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.userModifyPasswordAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for workspaceAdminList
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected workspaceAdminListAxiosOptionsCreator(input: { id?: number, username?: string, fuzzyUsername?: string, assigneeId?: number, skip?: number, take?: number, sort?: string, columns?: Array<string>, populations?: Array<string>,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/admin/workspaces`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.id !== undefined) {
                localVarQueryParameter['id'] = input.id;
            }

            if (input.username !== undefined) {
                localVarQueryParameter['username'] = input.username;
            }

            if (input.fuzzyUsername !== undefined) {
                localVarQueryParameter['fuzzyUsername'] = input.fuzzyUsername;
            }

            if (input.assigneeId !== undefined) {
                localVarQueryParameter['assigneeId'] = input.assigneeId;
            }

            if (input.skip !== undefined) {
                localVarQueryParameter['skip'] = input.skip;
            }

            if (input.take !== undefined) {
                localVarQueryParameter['take'] = input.take;
            }

            if (input.sort !== undefined) {
                localVarQueryParameter['sort'] = input.sort;
            }

            if (input.columns) {
              localVarQueryParameter['columns'] = input.columns.join(COLLECTION_FORMATS.csv);
            }

            if (input.populations) {
              localVarQueryParameter['populations'] = input.populations.join(COLLECTION_FORMATS.csv);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/admin/workspaces
     * @param input
     * @param {*} [options] Override http request option.
     */
    public workspaceAdminList<T = WorkspacePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 用户名
         */
        username?: string,
              /**
         * 用户名 (模糊匹配)
         */
        fuzzyUsername?: string,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, assigneeId
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.workspaceAdminListAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/admin/workspaces
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useWorkspaceAdminList<T = WorkspacePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 用户名
         */
        username?: string,
              /**
         * 用户名 (模糊匹配)
         */
        fuzzyUsername?: string,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, assigneeId
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.workspaceAdminListAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for workspaceCreate
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected workspaceCreateAxiosOptionsCreator(input: { body: WorkspaceCreateInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/workspaces`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("WorkspaceCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/workspaces
     * @param input
     * @param {*} [options] Override http request option.
     */
    public workspaceCreate<T = Workspace>(input: {
        body: WorkspaceCreateInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.workspaceCreateAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/workspaces
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useWorkspaceCreate<T = Workspace>(input: {
        /**
         * 
         */
        body: WorkspaceCreateInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.workspaceCreateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for workspaceList
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected workspaceListAxiosOptionsCreator(input: { id?: number, username?: string, fuzzyUsername?: string, assigneeId?: number, skip?: number, take?: number, sort?: string, columns?: Array<string>, populations?: Array<string>,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/workspaces`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.id !== undefined) {
                localVarQueryParameter['id'] = input.id;
            }

            if (input.username !== undefined) {
                localVarQueryParameter['username'] = input.username;
            }

            if (input.fuzzyUsername !== undefined) {
                localVarQueryParameter['fuzzyUsername'] = input.fuzzyUsername;
            }

            if (input.assigneeId !== undefined) {
                localVarQueryParameter['assigneeId'] = input.assigneeId;
            }

            if (input.skip !== undefined) {
                localVarQueryParameter['skip'] = input.skip;
            }

            if (input.take !== undefined) {
                localVarQueryParameter['take'] = input.take;
            }

            if (input.sort !== undefined) {
                localVarQueryParameter['sort'] = input.sort;
            }

            if (input.columns) {
              localVarQueryParameter['columns'] = input.columns.join(COLLECTION_FORMATS.csv);
            }

            if (input.populations) {
              localVarQueryParameter['populations'] = input.populations.join(COLLECTION_FORMATS.csv);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/workspaces
     * @param input
     * @param {*} [options] Override http request option.
     */
    public workspaceList<T = WorkspacePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 用户名
         */
        username?: string,
              /**
         * 用户名 (模糊匹配)
         */
        fuzzyUsername?: string,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, assigneeId
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.workspaceListAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/workspaces
     * @param input
     * @param {*} [options] Override http request option.
     * @tag DefaultApi
     */
     public useWorkspaceList<T = WorkspacePaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 用户名
         */
        username?: string,
              /**
         * 用户名 (模糊匹配)
         */
        fuzzyUsername?: string,
              /**
         * 指派人 ID
         */
        assigneeId?: number,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, assigneeId
         */
        columns?: Array<string>,
              /**
         * 扩展字段，多个使用 , 分割。可选值：creator, workspace, assignee
         */
        populations?: Array<string>,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.workspaceListAxiosOptionsCreator(input, options));
    }

        /**
         * AxiosRequestOptions generator for browserProfileInternalGet
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected browserProfileInternalGetAxiosOptionsCreator(input: { id: number,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/browser-profiles/internal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/browser-profiles/internal/{id}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public browserProfileInternalGet<T = BrowserProfile>(input: {
        id: number,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.browserProfileInternalGetAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/browser-profiles/internal/{id}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag InternalApi
     */
     public useBrowserProfileInternalGet<T = BrowserProfile>(input: {
        /**
         * 
         */
        id: number,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.browserProfileInternalGetAxiosOptionsCreator(input, options));
    }

        /**
         * AxiosRequestOptions generator for webhookIncomingReceive
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected webhookIncomingReceiveAxiosOptionsCreator(input: { channel: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/webhook/incoming/{channel}`
                .replace(`{${"channel"}}`, encodeURIComponent(String(input.channel)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/webhook/incoming/{channel}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public webhookIncomingReceive<T = unknown>(input: {
        channel: string,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.webhookIncomingReceiveAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/webhook/incoming/{channel}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag PublicApi
     */
     public useWebhookIncomingReceive<T = unknown>(input: {
        /**
         * 
         */
        channel: string,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.webhookIncomingReceiveAxiosOptionsCreator(input, options));
    }

        /**
         * AxiosRequestOptions generator for sessionCreate
         * 登录
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected sessionCreateAxiosOptionsCreator(input: { body: SessionCreateInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/sessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("SessionCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 登录
     * @url /api/v1/sessions
     * @param input
     * @param {*} [options] Override http request option.
     */
    public sessionCreate<T = User>(input: {
        body: SessionCreateInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.sessionCreateAxiosOptionsCreator(input, options));
    }
    /**
     * 登录
     * @url /api/v1/sessions
     * @param input
     * @param {*} [options] Override http request option.
     * @tag SessionsApi
     */
     public useSessionCreate<T = User>(input: {
        /**
         * 
         */
        body: SessionCreateInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.sessionCreateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for sessionCreateRoot
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected sessionCreateRootAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/sessions/create-admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/sessions/create-admin
     * @param input
     * @param {*} [options] Override http request option.
     */
    public sessionCreateRoot<T = unknown>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.sessionCreateRootAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/sessions/create-admin
     * @param input
     * @param {*} [options] Override http request option.
     * @tag SessionsApi
     */
     public useSessionCreateRoot<T = unknown>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.sessionCreateRootAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for sessionDelete
         * 退出登录
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected sessionDeleteAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/sessions/mine`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'DELETE',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 退出登录
     * @url /api/v1/sessions/mine
     * @param input
     * @param {*} [options] Override http request option.
     */
    public sessionDelete<T = unknown>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.sessionDeleteAxiosOptionsCreator(input, options));
    }
    /**
     * 退出登录
     * @url /api/v1/sessions/mine
     * @param input
     * @param {*} [options] Override http request option.
     * @tag SessionsApi
     */
     public useSessionDelete<T = unknown>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.sessionDeleteAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for sessionGetMine
         * 获取当前登录信息
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected sessionGetMineAxiosOptionsCreator(input: {  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/sessions/mine`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 获取当前登录信息
     * @url /api/v1/sessions/mine
     * @param input
     * @param {*} [options] Override http request option.
     */
    public sessionGetMine<T = User>(input: {
 },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.sessionGetMineAxiosOptionsCreator(input, options));
    }
    /**
     * 获取当前登录信息
     * @url /api/v1/sessions/mine
     * @param input
     * @param {*} [options] Override http request option.
     * @tag SessionsApi
     */
     public useSessionGetMine<T = User>(input: {
 },options?: AxiosRequestConfig) {
        return useAxios<T>(this.sessionGetMineAxiosOptionsCreator(input, options));
    }

        /**
         * AxiosRequestOptions generator for tokenCreate
         * 创建 Token
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected tokenCreateAxiosOptionsCreator(input: { body: TokenCreateInput,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/tokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'POST',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // const needsSerialization = ("TokenCreateInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  input.body // needsSerialization ? JSON.stringify(input.body || {}) : (body || "");
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 创建 Token
     * @url /api/v1/tokens
     * @param input
     * @param {*} [options] Override http request option.
     */
    public tokenCreate<T = Token>(input: {
        body: TokenCreateInput,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.tokenCreateAxiosOptionsCreator(input, options));
    }
    /**
     * 创建 Token
     * @url /api/v1/tokens
     * @param input
     * @param {*} [options] Override http request option.
     * @tag TokenApi
     */
     public useTokenCreate<T = Token>(input: {
        /**
         * 
         */
        body: TokenCreateInput,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.tokenCreateAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for tokenDelete
         * 删除 Token
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected tokenDeleteAxiosOptionsCreator(input: { id: string,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(input.id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'DELETE',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 删除 Token
     * @url /api/v1/tokens/{id}
     * @param input
     * @param {*} [options] Override http request option.
     */
    public tokenDelete<T = unknown>(input: {
        id: string,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.tokenDeleteAxiosOptionsCreator(input, options));
    }
    /**
     * 删除 Token
     * @url /api/v1/tokens/{id}
     * @param input
     * @param {*} [options] Override http request option.
     * @tag TokenApi
     */
     public useTokenDelete<T = unknown>(input: {
        /**
         * 
         */
        id: string,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.tokenDeleteAxiosOptionsCreator(input, options));
    }
        /**
         * AxiosRequestOptions generator for tokenList
         * 
         * @param input
         * @param {*} [options] Override http request option.
         */
        protected tokenListAxiosOptionsCreator(input: { id?: number, skip?: number, take?: number, sort?: string, columns?: Array<string>,  }, options: AxiosRequestConfig = {}): AxiosRequestConfig {
            const localVarPath = `/api/v1/tokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions: AxiosRequestConfig = {
              method: 'GET',
              ...options
            }
            const localVarHeaderParameter: AxiosRequestConfig['headers'] = {};
            const localVarQueryParameter: AxiosRequestConfig['params'] = {};

            // authentication token required
            if (this.configuration && this.configuration.apiKey) {
                const localVarApiKeyValue = typeof this.configuration.apiKey === 'function'
					? this.configuration.apiKey("x-token")
					: this.configuration.apiKey;
                localVarHeaderParameter["x-token"] = localVarApiKeyValue;
            }

            if (input.id !== undefined) {
                localVarQueryParameter['id'] = input.id;
            }

            if (input.skip !== undefined) {
                localVarQueryParameter['skip'] = input.skip;
            }

            if (input.take !== undefined) {
                localVarQueryParameter['take'] = input.take;
            }

            if (input.sort !== undefined) {
                localVarQueryParameter['sort'] = input.sort;
            }

            if (input.columns) {
              localVarQueryParameter['columns'] = input.columns.join(COLLECTION_FORMATS.csv);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.params);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.baseURL = this.configuration.basePath
            localVarRequestOptions.url = url.format(localVarUrlObj)
            return localVarRequestOptions;
        }

    /**
     * 
     * @url /api/v1/tokens
     * @param input
     * @param {*} [options] Override http request option.
     */
    public tokenList<T = TokenPaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, accessKey
         */
        columns?: Array<string>,
       },options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios(this.tokenListAxiosOptionsCreator(input, options));
    }
    /**
     * 
     * @url /api/v1/tokens
     * @param input
     * @param {*} [options] Override http request option.
     * @tag TokenApi
     */
     public useTokenList<T = TokenPaginatedResult>(input: {
        /**
         * 主键
         */
        id?: number,
              /**
         * 分页参数，跳过条数。默认为 0
         */
        skip?: number,
              /**
         * 分页参数，获取条数。默认为 20
         */
        take?: number,
              /**
         * 排序字段，倒序使用 - 前缀，正序不含前缀，多个使用 , 分割。支持字段：id, createdAt, updatedAt, lastLoggedAt
         */
        sort?: string,
              /**
         * 选取字段，默认为全部。多个使用 , 分割。可选值：id, createdAt, updatedAt, createdBy, updatedBy, name, accessKey
         */
        columns?: Array<string>,
       },options?: AxiosRequestConfig) {
        return useAxios<T>(this.tokenListAxiosOptionsCreator(input, options));
    }

};
